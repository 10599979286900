// Main
$body-font-size: 16px;
$body-line-height: 20px;
$body-color: $c-holly;
$main-color: $c-christi;
$details-color: $c-lightning-yellow;
$main-font: 'Fira Sans';

:root {
  --main-color: #0C7CAC; 
  --details-color: #0C7CAC;
  --main-color-dark: #0875A4;
  --main-color-light: #C0DDF8;
  --promo-box-slider-bg: #0875A4;
  --promo-box-slider-bg-light: #0C7CAC;
  --promo-box-slider-sign-color: #B6EDFF;

  //servicesPromoBox prezydent
  --services-promo-box-bg: #C0DDF8;
  --services-promo-box-right-color: #73B0E2;
  --services-promo-box-details-color: #529FDF;

}

// Visual
$section-space: 35px; 
$border-radius-val: 3px; 

$transition: all .4s ease;
$transition-speed: all .2s ease;
$transition-slow: all .6s ease;

$overlay-light-bg: rgba($c-black, .3);
$overlay-dark-bg: rgba($c-black, .6);

$boxShadow: 0px 2px 4px rgba(0, 0, 0, 0.25);

$scale-hover: 1.07;

// BUS TIMETABLE
$mainBusColor: var(--main-color);
$accentBusColor: var(--details-color);
$lightMainColor: $c-cerulean;
$hoverColor: $c-solitude;
$secondaryBusColor: $c-dove-gray;
$detailsBusColor: $c-gallery-appr;
$borderBusColor: $c-mercury-approx;
$lightBg: $c-alabaster;
$lightBorderColor: $c-cornflower;
$suspendedLinesColor: $c-amaranth;
//$canceledLinesColor: $c-ronchi;
$canceledLinesColor: $c-newronchi;
$whiteColor: $c-white;
$busBorderColor: $c-concrete-appr;
$lightgray: $c-alto;
$disabledText: #22222266;
