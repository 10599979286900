@use "../../../../../../assets/sass/abstract/responsive" as rwd;
@use "../../../../../../assets/sass/abstract/_mixins" as mixin;

.busContent {
  margin: 0 auto;

  &.allStops {
    .accordionContent .accordionHeader {
      align-items: center;

      .busDirection {
        font-size: 20px;
      }
    }
  }

  .allStops {
    @include rwd.breakpoint(mobile) {
      margin-top: 30px;
    }
  }
}

// .headerContentImg,
.onlyPrint {
  display: none;
}

.arrowCss {
  content: "";
  position: absolute;
  transition: $transition;
  width: 0;
  height: 0;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 8px solid var(--main-color);
  transform: rotate(0deg);
}

.smallBtn {
  @include mixin.font(12px, false, 700);
  position: relative;
  padding: 7px 15px 7px 24px;
  text-decoration: none;
  transition: $transition;
  display: inline-block;
  height: 34px;
  text-transform: uppercase;

  @include rwd.breakpoint(mobile) {
    margin-bottom: 15px;
  }

  @include rwd.breakpoint(tabletmobile) {
    display: none;
  }

  &:after {
    @extend .arrowCss;
    left: 10px;
    top: 35%;
    border-left: 8px solid $mainBusColor;
    transform: rotate(180deg);
  }

  &:hover,
  &:focus {
    @include rwd.breakpoint(smallUp) {
      text-decoration: underline;

      &:after {
        left: 5px;
      }
    }
  }
}

.iconWithBg {
  @include mixin.flexCenter();
  background-color: $mainBusColor;
  height: 160px;
  width: 160px;
  transition: $transition;
  border: 1px solid transparent;

  img {
    transition: $transition;
    transform: scale(1);
  }

  @include rwd.breakpoint(mobile) {
    display: none;
  }
}

.titleSection {
  @include mixin.font(18px, 26px, 700);
  text-transform: uppercase;
  margin: 0;
}

// tooltip - przebieg trasy
[data-title] {
  position: relative;

  &:focus::after,
  &:focus-within::after,
  &:hover:after {
    opacity: 1;
    transition: all 0.1s ease 0.3s;
    visibility: visible;

    @include rwd.breakpoint(tabletmobile) {
      display: none;
    }
  }

  &:after {
    content: attr(data-title);
    @include mixin.font(12px, 20px);
    @include mixin.position(absolute, false, false, 40px, 30%);
    padding: 4px 4px 4px 8px;
    color: #222;
    background-color: $borderBusColor;
    width: 340px;
    box-shadow: $boxShadow;
    opacity: 0;
    z-index: 99999;
    visibility: hidden;
    white-space: pre-line;
    transition: all .4s ease;
    z-index: 1000;
  }

  &.without-after-element {
    &::after {
      display: none;
    }
  }
}

.twoColumns {
  .busTimetableList {
    @include rwd.breakpoint(largesmall) {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    }
  }
}

.sectionLink {
  @include mixin.font(16px, 24px, false, $mainBusColor);
  display: inline-block;
  margin-top: 30px;
  padding: 10px 0;
  text-decoration: underline;
  transition: $transition;

  &:hover,
  &:focus {
    @include rwd.breakpoint(smallUp) {
      color: darken(#0C7CAC, 10%);
    }
  }
}

.tooltipText {
  @include mixin.font(12px, 16px);
}

.twoSideWrapper {
  @include rwd.breakpoint(mobileUp) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .routeTooltip,
  .timetable-versions-alert  {
    margin: 0;
    flex: 1;
  }

  .routeTooltip {
    padding: 0;
    text-align: right;
    color: $c-mine-shaft;

    @include rwd.breakpoint(tabletmobile) {
      display: none;
    }
  }
}

.busNumberWrapper {
  @include mixin.flexCenter;
  height: 160px;
  width: 160px;
  border: 1px solid $borderBusColor;

  @include rwd.breakpoint(mobile) {
    display: none;
  }

  .busNumber {
    @include mixin.font(60px, false, 700);

    @include rwd.breakpoint(phonesDown) {
      font-size: 48px;
    }
  }

  + .headerContent  {
    @include rwd.breakpoint(mobile) {
      margin-left: 0;
    }
  }
}

.smallNumberWrapper {
  @include mixin.flexCenter();
  padding: 17px 0;
  background-color: $lightBg;
  width: 100px;
  margin-top: 0;
  margin-bottom: 0;

  .smallNumber {
    @include mixin.font(34px, false, 700);
  }
}

.line-suspended,
.line-changed {
  position: relative;

  &:before {
    @include mixin.position(absolute, false, -1px, -1px);
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-width: 0 0 14px 14px;
    border-style: solid;
  }
}

.line-suspended {
  border: solid $suspendedLinesColor 2px !important;

  &:before {
    border-color: transparent transparent $suspendedLinesColor transparent;
  }
}

.line-changed {
  border: solid $canceledLinesColor 2px !important;

  &:before {
    border-color: transparent transparent $canceledLinesColor transparent;
  }
}

.arrowDown {
  position: relative;

  &:after {
    @extend .arrowCss;
    right: 20px;
    top: 45%;
    transform: rotate(90deg);
  }
}

.leftBeam {
  padding: 6px 13px;
  border-left: 3px solid $accentBusColor;
  margin-top: 30px;
  margin-bottom: 20px;

  @include rwd.breakpoint(mobile) {
    margin-top: 20px;
  }
}

.contentWrapper {
  @include rwd.breakpoint(tablet) {
    margin-top: 5px;
    margin-left: 20px;
  }
}

.timetableTableWrapper {
  .timetableTable {
    display: none;

    &.active {
      display: block;
    }

    &:last-of-type {
      margin-bottom: 0;
      margin-right: 0;
    }

    .printPageWrapper {
      @include rwd.breakpoint(tablet) {
        margin: 20px 0 0;
      }

      @include rwd.breakpoint(mobile) {
        display: none;
      }
    }
  }

  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

.updatesContentTab {
  display: none;

  &:nth-of-type(2) {
    display: block;
  }

  &.active {
    display: block;
  }
}

.tableTitle {
  @include mixin.font(16px, 22px);
  background-color: $accentBusColor;
  border: 1px solid $accentBusColor;
  color: $whiteColor;
  margin-bottom: 5px;
  padding: 5px;
  text-align: center;
}

.bus-table {
  max-width: 100%;
  padding-top: 2px;
  width: 100% !important;

  .tbody {
    border: 1px solid $borderBusColor;
    border-top: none;
  }

  .th,
  li[time-hour-minutes] {
    font-weight: 600;
    width: 42px;
    height: 42px;
    @include mixin.flexCenter;

    @include rwd.breakpoint(mobile) {
      width: 36px;
      height: 36px;
    }
  }

  .th.colorText {
    background-color: $busBorderColor;
    font-weight: 900;
  }

  .out-dated {
    color: $c-gray;
  }

  li[time-hour] {
    transition: $transition;

    .th.colorText {
      border-top: 1px solid $whiteColor;
    }

    &:nth-child(odd) {
      background-color: $whiteColor;
    }

    &:nth-child(even) {
      background-color: $busBorderColor;

      .th.colorText {
        background-color: darken($busBorderColor, 4%);
      }
    }

    &:hover,
    &:focus {
      @include rwd.breakpoint(smallUp) {
        background-color: $hoverColor;
      }
    }
  }

  li[time-hour] {
    display: flex;

    .d-flex {
      flex-wrap: wrap;
    }
  }

  li[time-hour-minutes] {
    position: relative;
  }

  .smallLegendMark {
    @include mixin.font(10px, false, 500);
    @include mixin.position(absolute, 1px, 1px);
    z-index: 10;
    text-transform: uppercase;
  }
}

// Shadow do listy przystankow ze scrollem
.shadow {
  transition: $transition;
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 50px;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    185deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  z-index: 10;
}

.linearShadow {
  position: relative;

  &:before {
    bottom: 0;
    @extend .shadow;
  }
}

.updatesTabList,
.tabList {
  li {
    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  .tabItem {
    @include mixin.font(18px, 22px, 600, $c-emperor);
    background: $busBorderColor;
    border-bottom: 0;
    border: 3px solid transparent;
    cursor: pointer;
    height: 100%;
    letter-spacing: 0.3px;
    padding: 15px;
    transition: $transition;
    width: 100%;
  }
}

.tabList {
  display: flex;
  max-width: 740px;
  position: relative;

  &::after {
    @include mixin.position(absolute, false, 1px, 0, 1px);
    content: '';
    display: block;
    height: 3px;
    background-color: $mainBusColor;
  }

  li {
    margin: 0 3px;
    width: 100%;

    &:first-of-type {
      .active {
        border-left: 1px solid $borderBusColor;
      }
    }

    &:last-of-type {
      .active {
        border-right: 1px solid $borderBusColor;
      }
    }

    & > div {
      height: 100%;
    }
  }

  .tabItem {
    @include rwd.breakpoint(phonesDown) {
      @include mixin.font(16px, 20px);
    }
    
    @include rwd.breakpoint(smallPhones) {
      padding: 8px 15px;
    }

    &.active {
      background-color: $c-white;
      border: 3px solid $mainBusColor;
      border-bottom: none;
      color: inherit;
      font-weight: 800;
      position: relative;

      &::after {
        @include mixin.position(absolute, false, 0, -1px, 0);
        background-color: $whiteColor;
        content: '';
        display: block;
        height: 5px;
        z-index: 1;
      }
    }
  }
}

.updatesSlider,
.announcedSlider {
  .splide__arrow {
    background: $c-white;
    border-radius: 0;
    padding: 0;
    width: 40px;

    &:after {
      @extend .arrowCss;
      top: 45%;
      display: none;
    }

    &--next {
      &:after {
        right: 5px;
        transform: rotate(0deg);
      }
    }

    &--prev {
      &:after {
        left: 5px;
        transform: rotate(180deg);
      }
    }

    /* svg {
      display: none;
    } */
  }
}

.announcedSlider {
  padding: 10px 14px 14px;

  @include rwd.breakpoint(mobile) {
    padding: 5px 10px 10px;
  }

  .splide__arrow {
    @include rwd.breakpoint(mobileUp) {
      height: 100%;
      top: 45%;

      &::after {
        top: 45%;
      }

      &--next {
        right: 14px;
        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0) 0%,
          $busBorderColor 100%
        );

        &:hover {
          @include rwd.breakpoint(smallUp) {
            &:after {
              right: 14px;
            }
            
            svg {
              fill: var(--main-color);
            }
          }
        }
      }

      &--prev {
        left: 14px;
        background: linear-gradient(
          -90deg,
          rgba(255, 255, 255, 0) 0%,
          $busBorderColor 100%
        );

        &:hover {
          @include rwd.breakpoint(smallUp) {
            &:after {
              left: 14px;
            }
            
            svg {
              fill: var(--main-color);
            } 
          }
        }
      }
    }

    svg {
      fill: $c-dove-gray;
    } 

    @include rwd.breakpoint(mobile){
      display: none;
    }
  }
}

.updatesSlider {
  margin: 30px 0;
  position: relative;

  @include rwd.breakpoint(mobile) {
    margin: 20px 0;
  }

  &::after {
    @include mixin.position(absolute, false, 0, 0, 0);
    content: '';
    display: block;
    height: 4px;
    background-color: $mainBusColor;
  }

  .updatesTabList {
    li {
      margin-right: 20px;

      &.activeLi {
        .tabItem {
          color: $c-white;
          background-color: $accentBusColor;

          time {
            color: $whiteColor;
          }
        }
      }
    }
  }

  .tabItem {
    white-space: nowrap;
    padding: 15px 20px 18px 20px;
    display: block;
    text-decoration: none;
    color: inherit;

    @include rwd.breakpoint(phonesDown) {
      padding: 10px;
    }

    time {
      color: $mainBusColor;
    }
  }

  .splide__arrow  {
    height: 120%;

    &--next {
      right: 0;
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        $c-white 100%
      );

      &:hover {
        @include rwd.breakpoint(smallUp) {
          &:after {
            right: 0px;
          }
        }
      }
    }

    &--prev {
      left: 0;
      background: linear-gradient(
        -90deg,
        rgba(255, 255, 255, 0) 0%,
        $c-white 100%
      );

      &:hover {
        @include rwd.breakpoint(smallUp) {
          &:after {
            left: 0px;
          }
        }
      }
    }

    svg {
      display: none;
    }
  }
}

.timetable-versions-alert {
  @include mixin.font(14px, 20px);
  background-color: $busBorderColor;
  box-shadow: $boxShadow;
  margin-top: 30px;
  padding: 10px;

  .txtCategoryLabel {
    top: 0;
  }
}

.boxPopupContent,
.busPopupMapWrapper {
  border-radius: $border-radius;
  height: 550px;
  width: 760px;

  .busPopupMap,
  .boxPopupMapAll {
    height: 100%;
    width: 100%;
  }

  .leaflet-control-container {
    .leaflet-top.leaflet-right {
      display: none;
    }
  }

  .leaflet-tile-container.leaflet-zoom-animated {
    filter: grayscale(1);
  }

  .leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
    touch-action: manipulation;
  }

  .fancybox-button {
    @include mixin.flexCenter() ;

    svg {
      height: 40px !important;
      width: 40px !important;
    }
  }
}

// Zegar
.closest-trips {
  padding: 0 0 10px;

  @include rwd.breakpoint(smallUp) {
    margin-top: 34px;
  }

  @include rwd.breakpoint(phonesUp) {
    @include mixin.flexValue(flex-start);
  }

  + .tabList {
    margin-top: 20px;
  }

  .closest-trips-div {
    height: 86px;

    @include rwd.breakpoint(phonesDown) {
      height: 46px;
    }
  }

  .closest-trips-placeholder {
    background: $busBorderColor;
    display: block;
    height: 15px;
    min-width: 280px;
    border-radius: $border-radius-val;
    margin-bottom: 10px;

    @include rwd.breakpoint(phonesDown) {
      min-width: 140px;
      width: 140px;

      &:last-of-type {
        display: none;
      }
    }
  }

  .closest-trips-clock {
    @include mixin.font(40px, false, 700);
    @include mixin.flexCenter;
    font-family: monospace;
    background:$busBorderColor;
    border-radius: $border-radius-val;
    width: 217px;
    height: 63px;
    margin-bottom: 0px;
    margin-top: 0px;

    @include rwd.breakpoint(phonesUp) {
      margin-right: 20px;
    }

    @include rwd.breakpoint(mobile) {
      width: 177px;
      font-size: 34px;
      height: 53px;
      margin-right: 10px;
      flex-shrink: 0;
    }

    @include rwd.breakpoint(smallPhones) {
      width: 100px;
      font-size: 18px;
      padding: 0 8px;
      height: 44px;
    }

    @include rwd.breakpoint(phonesDown) { 
      display: none;
    }
  }

  .closest-trips-text {
    @include mixin.font(20px, 26px, 700);
    display: inline;

    @include rwd.breakpoint(phonesDown) { 
      font-size: 16px;
    }
  }

  .closest-trips-variants {
    padding-top: 5px;
    padding-left: 0px;
    font-size: 20px;
    color: $secondaryBusColor;
    margin-top: 5px;
    margin-bottom: 0px;

    @include rwd.breakpoint(phonesDown) { 
      font-size: 14px;
      margin-top: 0;
      padding-top: 0;
    }
  }

  .closest-trips-note {
    font-size: 11px;
    color: $c-gray;
    padding-top: 0px;
    margin-top: 10px;
    margin-bottom: 0px;
    
    @include rwd.breakpoint(phonesDown) { 
      display: none;
    }
  }
}

.mobileWrapper {
  @include rwd.breakpoint(smallUp) {
    display: none;
  }

  @include rwd.breakpoint(tabletmobile) {
    border-radius: $border-radius-val;
    padding: 10px 0;
    background-color: $c-lavender-blush;
    margin-bottom: 10px;

    .readMore {
      @include mixin.font(13px, false, 600);
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 8px;
      padding-right: 24px;
      white-space: nowrap;

      &::after {
        border-left-color: $body-color;
        right: 8px;
      }
    }
  }
}

// Najblizsze godziny odjazdów
li[time-hour-minutes].highlighted__time0,
li[time-hour-minutes].highlighted__time1,
li[time-hour-minutes].highlighted__time2 {
  border-radius: $border-radius-val;
  color: $c-white;
  height: 36px;
  margin: 2px;
  width: 36px;

  @include rwd.breakpoint(mobile) {
    height: 32px;
    width: 32px;
  }

  .smallLegendMark {
    top: -1px;
    right: 3px;
  }
}

.highlighted {
  &__time0 {
    background-color: $mainBusColor;
  }
  &__time1 {
    background-color: darken(#0C7CAC, 15%);
  }
  &__time2 {
    background-color: darken(#0C7CAC, 22%);
  }
}