// SCSS Color Variables
// Named with this: http://chir.ag/projects/name-that-color

$c-black: #000000;
$c-white: #ffffff;

$c-alabaster: #f7f7f7;
$c-alto-appr: #dcdcdc;
$c-alto: #cfcfcf;
$c-amaranth: #E51553;
$c-anakiwa: #8dd8ff;
$c-azure: #316db4;
$c-bermuda: #84c2db;
$c-bombay: #aaaaac;
$c-botticelli: #D5DDE8;
$c-boulder-appr: #757575;
$c-boulder: #757474;
$c-catskill-white: #eff4f7;
$c-cerulean: #109acd;
$c-christi: #4a930e;
$c-concrete-appr: #f2f2f2;
$c-concrete: #f3f3f3;
$c-cornflower: #8ab5e0;
$c-curious-blue: #358be3;
$c-denim: #0f72db;
$c-dodger-blue: #228af5;
$c-dove-gray: #656565;
$c-emperor: #4F4F4F; 
$c-fuchsia-blue: #7758ca;
$c-gallery-appr: #ececec;
$c-gallery: #eeeeee;
$c-gray: #8F8F8F;
$c-green-leaf-dark: #2d5909;
$c-green-leaf: #34670a;
$c-holly: #001209;
$c-lavender-blush: #FFE7EC;
$c-lightning-yellow: #fcc41a;
$c-lima: #85ba16;
$c-mercury-approx: #e6e6e6;
$c-mercury: #e5e5e5;
$c-midnight-blue: #00376a;
$c-mirage: #1e2838;
$c-mobster: #77737a;
$c-mystic: #dee5eb;
$c-newronchi: #C18A02;
$c-onahau: #d1f4ff;
$c-rhino: #313a62;
$c-rolling-stone: #808285;
$c-ronchi: #F2C34A;
$c-royal-blue: #3081ea;
$c-royal-blue: #3c6cde;
$c-scooter: #30b5de;
$c-silver-chalice: #9f9f9f;
$c-solitude: #dde7ff;
$c-stratos: #000038;
$c-tundora: #444444;
$c-venice-blue: #0a5299;
$c-white-ice: #d6f0f8;
//$c-darken-main-5: #1081b2;
$c-mine-shaft: #333333;
