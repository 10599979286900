// The forced-colors media feature indicates whether or not the browser is currently in forced-colors mode.

@media (forced-colors: active) {
  $forcedBorder: 1px solid transparent;

  .forcedColors {
    .bus-table .out-dated {
      text-decoration: line-through;
    }

    .accordionContent .accordionBody .listItem.active .listLinkWrapper {
      border: 8px solid transparent;
    }

    .accordionContent .accordionBody #accordionWithScroll .simplebar-track.simplebar-vertical,
    .simplebar-track.simplebar-vertical {
      width: 5px;
    }

    .tabList .tabItem.active {
      border-bottom: 4px solid transparent;
      z-index: 1;
    }

    .announcedWrapper,
    .importantNote .stickyNote,
    .accordionContent .accordionBody,
    .accordionContent .accordionHeader,
    .busCrossItem .busCrossLink,
    .busTimetableContainer .lightBgWrapper,
    .accordionContent .accordionBody .listItem .colorDot,
    .announcedWrapper .announcedBox .announcedItem a,
    .announcedWrapper .title.importantNote::before {
      border: $forcedBorder;
    }

    li[time-hour-minutes].highlighted__time0,
    li[time-hour-minutes].highlighted__time1,
    li[time-hour-minutes].highlighted__time2 {
      border: 2px solid transparent;
    }

    [data-title]:after {
      border: 20px solid transparent;
    }

    .accordionContent .accordionHeader .busDirectionWrapper {
      border-left: $forcedBorder;
    }

    .bus-table li[time-hour] .th.colorText {
      border-right: $forcedBorder;
    }

    .bus-table li[time-hour] {
      border-top: $forcedBorder;
    }
  }
}