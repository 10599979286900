@use "../../../../../../assets/sass/abstract/responsive" as rwd;
@use "../../../../../../assets/sass/abstract/_mixins" as mixin;

// bus stop - wszystkie przystanki
.busStopListing {
  margin-top: 30px;

  .smallNumberWrapper {
    padding: 27px 0;
  }

  .accordionContent .accordionBody {
    .accordionList {
      border-left: none;
      margin-left: 0;
      list-style: disc;

      @include rwd.breakpoint(mobileUp) {
        columns: 2;
      }

      .listItem {
        position: relative;
        margin-left: 18px;

        &::marker {
          color: $mainBusColor;
          font-size: 1.4em;
        }

        a {
          text-decoration: none;
          transition: all 0.4s ease;
        }

        &:hover,
        &:focus {
          @include rwd.breakpoint(smallUp) {
            a {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}

// BUS STOP SELECTION
.busStopTwoAccordionContainer {
  margin-top: 30px;

  @include rwd.breakpoint(mobile) {
    margin-top: 20px;
  }

  .accordionContent {
    @media (min-width: 680px) {
      margin-bottom: 0;
    }

    @media (max-width: 679px) {
      margin-bottom: 20px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    &:first-of-type {
      @include rwd.breakpoint(tablet) {
        margin-bottom: 30px;
      }
    }
  }

  .busVariantShape {
    z-index: 40;

    .leaflet-tile-container.leaflet-zoom-animated {
      filter: grayscale(1);
    }

    .leaflet-top.leaflet-right {
      display: none;
    }
  }
}

// CONCRETE BUS STOP
.twoSides {
  display: flex;
  margin-top: 30px;

  @include rwd.breakpoint(tabletmobile) {
    flex-direction: column-reverse;
  }

  @include rwd.breakpoint(tabletmobile) {
    margin-top: 20px;
  }

  &__leftSide {
    @include rwd.breakpoint(largesmall) {
      margin-right: 15px;
    }

    @include rwd.breakpoint(smallUp) {
      flex: 1;
    }

    @include rwd.breakpoint(small) {
      flex-shrink: 0;
    }

    @include rwd.breakpoint(tablet) {
      display: flex;
      justify-content: space-between;
    }

    @include rwd.breakpoint(tabletmobile) {
      margin-top: 20px;
    }

    @include rwd.breakpoint(largePhones) {
      flex: 1; 
    }

    .printPageWrapper {
      @include rwd.breakpoint(mobileUp) {
        display: none;
      }

      @include rwd.breakpoint(mobile) {
        margin-top: 40px;
      }
    }

    & > div {
      @include rwd.breakpoint(tablet) {
        flex: 1;
      }
    }

    .accordionHeader {
      cursor: auto;
    }
  }

  &__rightSide {
    flex: 1;

    @include rwd.breakpoint(smallUp) {
      flex: 2;
    }

    @include rwd.breakpoint(smallUp) {
      margin-left: 20px;
    }
  }

  &.lastStop {
    .note {
      @include mixin.font(30px, 36px, false, $c-dove-gray);
      display: block;
      text-align: center;
      
      @include rwd.breakpoint(smallUp) {
        padding-top: 80px;
      }

      @include rwd.breakpoint(tablet) {
        @include mixin.font(26px, 32px);
        padding-top: 20px;
      }

      @include rwd.breakpoint(mobile) {
        @include mixin.font(20px, 28px);
        padding: 20px 0 10px;
      }
    }
  }
}

.no-timetable-today {
  @include mixin.font(24px, 32px, false, $c-dove-gray);
  display: block;
  text-align: center;
  
  @include rwd.breakpoint(smallUp) {
    padding-top: 60px;
  }

  @include rwd.breakpoint(tabletmobile) {
    padding: 20px 0;
  }

  @include rwd.breakpoint(tablet) {
    @include mixin.font(20px, 26px);
  }
    
  @include rwd.breakpoint(mobile) {
    @include mixin.font(18px, 24px);
  }
}

// BUS CROSS
.busCrossContainer {
  @media (max-width: 716px) {
    text-align: center;
  }
}

.busCrossItem {
  margin-bottom: 20px;
  display: inline-block;

  @include rwd.breakpoint(smallPhones) {
    width: 100%;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  .busCrossLink {
    background-color: $whiteColor;
    box-shadow: $boxShadow;
    color: initial;
    display: flex;
    height: 105px;
    position: relative;
    text-decoration: none;
    transition: $transition;

    @include rwd.breakpoint(smallPhones) {
      height: 80px;
    }

    &:hover,
    &:focus {
      @include rwd.breakpoint(smallUp) {
        color: $whiteColor;
        background-color: $accentBusColor;

        .colorText,
        .arrowRight,
        .busDirection,
        .smallTextWrapper {
          color: $whiteColor;
        }

        .postNumberWrapper {
          color: $whiteColor;
          background-color: $lightBorderColor;
        }
      }
    }
  }

  .smallNumberWrapper {
    padding: 0 20px;
    height: 100%;
    @include mixin.flexValue(center);
    background-color: initial;
    width: 90px;

    @include rwd.breakpoint(smallPhones) {
      width: 80px;
    }
  }

  .smallTextWrapper {
    @include mixin.font(14px, 18px);
    // @include mixin.flexValue(center);
    @include mixin.flexCenter();
    padding: 0 10px;
    text-align: center;
    height: 100%;
    color: $secondaryBusColor;
    border-left: 1px solid $borderBusColor;
    border-right: 1px solid $borderBusColor;
    width: 110px;

    @include rwd.breakpoint(smallPhones) {
      display: none;
    }
  }

  .busDirectionWrapper {
    padding: 0 10px;
    height: 100%;
    @include mixin.flexValue(center);

    @include rwd.breakpoint(smallPhones) {
      border-left: 1px solid $borderBusColor;
    }
  }

  .busDirection {
    @include mixin.font(14px, 20px, 600);
    color: $secondaryBusColor;

    @media (max-width: 716px) {
      text-align: left;
    }

    .colorText {
      @include mixin.font(16px, 20px);
      color: $accentBusColor;
      display: block;
    }
  }

  h2 {
    margin: 0;
  }

  .postNumberWrapper {
    @include mixin.font(13px, 18px, false, $c-emperor);
    @include mixin.position(absolute, 0, 0);
    display: inline-block;
    background-color: $lightBg;
    padding: 3px 10px;
    transition: $transition;
    letter-spacing: 0.2px;
  }
}

// BUS LIST
.busTimetableContainer {
  margin-top: 20px;
  margin-right: -2px;

  @include rwd.breakpoint(large) {
    margin-right: -4px;
  }

  .titleSection {
    text-align: center;
  }

  .lightBgWrapper {
    background-color: $detailsBusColor;
    padding: 15px;

    &.hideTitle {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      border: 0;
    }
  }

  .busTimetableList {
    display: grid;
    grid-auto-rows: 50px;
    grid-template-columns: repeat(auto-fit, minmax(62px, 1fr));

    @include rwd.breakpoint(mobile) {
      grid-template-columns: repeat(auto-fit, minmax(52px, 1fr));
    }

    &.smallTimetableList {
      @include rwd.breakpoint(phonesUp) {
        display: flex;
        height: 50px;
  
        li {
          width: 62px;
        }
      }

      &.specialTram {
        .busTimetableLink::after{
          bottom: 5px;
        }

        .tramNr {
          @include mixin.flexCenter();
          @include mixin.makeCircle(30px);
          border: 2px solid;
        }
      }
    } 
  }

  .busTimetableElement {
    .busTimetableLink {
      border: 1px solid $borderBusColor;
      transition: $transition;

      &:hover,
      &:focus {
        @include rwd.breakpoint(smallUp) {
          background-color: $accentBusColor;
          color: $whiteColor;
          position: relative;

          &:after {
            @include mixin.position(absolute, false, false, 10px, calc(50% - 15px));
            content: "";
            display: block;
            background-color: $whiteColor;
            height: 1px;
            width: 30px;
          }
        }
      }
    }

    [data-hide-route] {
      @include rwd.breakpoint(tabletmobile) {
        display: none;
      } 

      &.hide {
        display: none;
      }
    }
  }

  .busTimetableLink {
    @include mixin.font(16px, 20px, 700, inherit);
    @include mixin.flexCenter;
    height: 100%;
    width: 100%;
    text-transform: uppercase;
    text-decoration: none;
    outline-offset: 3px;
  }
}