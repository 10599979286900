@use "../../../../../../assets/sass/abstract/responsive" as rwd;
@use "../../../../../../assets/sass/abstract/_mixins" as mixin;

// Accordion
.accordionContent {
  margin-bottom: 20px;

  &:last-of-type {
    margin-bottom: 0;
  }

  h2 {
    margin: 0;
  }

  .accordionHeader {
    box-shadow: $boxShadow;
    cursor: pointer;
    display: flex;
    min-height: 74px;
    // margin: 0;

    width: 100%;
    border: none;
    display: inline-flex;
    background: transparent;
    padding: 0;

    .busDirectionWrapper {
      background-color: $whiteColor;
      padding: 10px 30px 10px 15px;
      width: 100%;

      text-align: left;

      @include rwd.breakpoint(smallPhones) {
        padding: 20px;
      }
    }

    .busDirection {
      @include mixin.font(16px, 22px, 700);
      letter-spacing: 0.5px;
      margin: 0;

      span {
        display: block;
        color: initial;
      }

      .colorText {
        color: $accentBusColor;

        .arrowRight {
          padding-right: 6px;
          display: contents;
        }

        .icon-right-open-mini::before{
          color: $accentBusColor;
        }

      }
    }
  }

  .accordionBody {
    box-shadow: $boxShadow;
    background: $whiteColor;
    padding-bottom: 20px;

    &.expanded {
      .moreInfoWrapper {
        display: none;
      }
    }

    #accordionWithScroll {
      height: 590px;
      overflow: scroll;
      margin-right: 8px;

      @include rwd.breakpoint(mobile) {
        height: 330px;
      }

      .simplebar-track.simplebar-vertical {
        width: 3px;
        background-color: $borderBusColor;
      }

      .simplebar-scrollbar:before {
        background-color: $mainBusColor;
      }
    }

    .accordionList {
      padding: 25px 10px 0 20px;
      margin-left: 18px;
      border-left: 1px solid $accentBusColor;
    }

    .listItem {
      margin-bottom: 14px;
      position: relative;

      &:last-of-type {
        margin-bottom: 0;

        .colorDot {
          &::after {
            @include mixin.position(absolute, false, false, -25px, -2px);
            content: "";
            display: block;
            background: $c-white;
            width: 14px;
            height: 25px;
          }
        }
      }

      &.hideItem {
        display: none;
      }

      .colorDot {
        @include mixin.makeCircle(9px, $accentBusColor);
        @include mixin.position(absolute, calc(50% - 4.5px), false, false, -25px);
        display: block;

        &:before {
          @include mixin.makeCircle(17px);
          @include mixin.position(absolute, calc(50% - 8.5px), false, false, -4px);
          content: "";
          display: block;
          border: 1px solid $accentBusColor;
          z-index: 2;
        }
      }

      &.active {
        .listLinkWrapper  {
          background-color: $accentBusColor;
          border-color: $accentBusColor;
        }

        .on-demand-badge-list,
        a {
          color: $whiteColor;
        }

        .topLabel {
          background-color: $mainBusColor;
          color:#cef0ff;
        }
      }
    }

    ::-webkit-scrollbar { 
      all: unset !important;
      width: 0 !important;
      height: 0 !important;
    }

    
  }

  .listLinkWrapper {
    @include mixin.flexValue(center, space-between);
    @include mixin.font(14px, 18px, 600);
    text-decoration: none;
    color: inherit;
    padding: 8px;
    background-color: $whiteColor;
    border: 1px solid $borderBusColor;
    border-radius: 10px;
    transition: $transition;
    position: relative;
    min-height: 37px;

    @include rwd.breakpoint(mobile) {
      min-height: 40px;
    }

    & > div {
      margin-left: 10px;
    }

    &:hover,
    &:focus {
      @include rwd.breakpoint(smallUp) {
        background-color: $accentBusColor;
        border-color: $accentBusColor;
        
        .on-demand-badge-list,
        a{
          color: $whiteColor;
        }

        .listLinkItem {
          a:first-of-type {
            text-decoration: underline;
          }
        }

        .topLabel {
          background-color: $mainBusColor;
          color:#cef0ff;
        }

        .linkIcon,
        .timeItem {
          color: $whiteColor;
        }
      }
    }

    .topLabel {
      @include mixin.font(10px, false, 400, $secondaryBusColor);
      @include mixin.position(absolute, -10px);
      background-color: $whiteColor;
      border-radius: 7px;
      left: 7px;
      padding: 1px 5px 0;
      transition: $transition;
    }

    .label {
      text-decoration: none;
      flex: 1;

      .on-demand-badge-list {
        margin-left: 4px;
      }
    }

    .listLinkItem {
      &:first-of-type {
        width: auto;
        flex: 1;
      }

      a {
        display: block;
        text-decoration: none;
        transition: $transition;
        width: 100%;
        color: inherit;
        padding: 2px 0;
      }
    }

    .linkIcon {
      color: $mainBusColor;
      text-decoration: none;
    }

    .timeItem {
      margin-left: 10px;
    }
    
    .linkIcon,
    .timeItem {
      transition: $transition;
    }
  }

  .moreInfoWrapper {
    text-align: center;
    padding-bottom: 10px;
    position: relative;
    margin-top: -16px;
    background-color: $whiteColor;
    z-index: 3;

    .moreInfo {
      @include mixin.font(16px, false, 600, $whiteColor);
      background-color: $mainBusColor;
      border-radius: $border-radius;
      border: none;
      cursor: pointer;
      padding: 7px 17px;
      position: relative;
    }

    &:before {
      top: -50px;
      bottom: auto;
    }
  }

  &.isLink {
    display: block;

    .accordionHeader {
      text-decoration: none;
      cursor: pointer;

      .smallNumber {
        color: initial;
        background-color: $lightBg;
        height: 100%;
        width: 100%;
        @include mixin.flexCenter();
      }

      .arrowDown {
        &:after {
          display: none;
        }
      }
    }
  }

  &.isCurled {
    @media (min-width: 680px) {
      margin-top: 30px;
    }

    .accordionBody {
      display: none;
    }
  }

  &.isExpanded {
    .accordionHeader {
      border-bottom: 3px solid $lightBg;
    }
  }

  &.isExpanded.isCurled,
  &.isExpanded.isCollapsable {
    .accordionHeader {
      .arrowDown {
        &:after {
          transform: rotate(270deg);
        }
      }
    }

    .accordionBody {
      display: block;
    }
  }

  &.onMobileClose {
    @media (min-width: 768px) {
      .accordionHeader {
        cursor: auto;

        .arrowDown {
          &:after {
            display: none;
          }
        }
      }

      .accordionBody {
        display: block;
      }
    }

    @media (max-width: 767px) {
      .accordionBody {
        display: none;
      }
    }
  }

  &.onMobileClose.isExpanded {
    .accordionHeader {
      .arrowDown {
        &:after {
          transform: rotate(270deg);
        }
      }
    }
  }
}
