@use "../../../../../../assets/sass/abstract/responsive" as rwd;
@use "../../../../../../assets/sass/abstract/_mixins" as mixin;

// Header
.busTimetableHeader {
  @include mixin.flexValue(center);

  .headerContent {
    flex: 1;

    @include rwd.breakpoint(mobileUp) {
      margin: 0 35px;
    }

    @include rwd.breakpoint(mobile) {
      margin: 0;
    }

    .line {
      &__changed,
      &__suspended {
        font-weight: 700;
        margin-top: 5px;
        display: inline-block;
      }

      &__changed {
        color: $canceledLinesColor;
      }

      &__suspended {
        color: $suspendedLinesColor;
      }
    }
  }

  .mainTitle {
    @include mixin.font(30px, 38px);
    font-weight: 900;
    margin: 0;

    @include rwd.breakpoint(phonesDown) {
      @include mixin.font(24px, 32px);
    }

    .uppercaseText {
      text-transform: uppercase;
    }

    .block {
      display: block;
    }
  }

  .sectionDesc {
    margin-bottom: 0;

    span {
      color: $secondaryBusColor;
      line-height: 14px;
    }
  }

  .infoBox {
    color: $secondaryBusColor;
    font-size: 14px;

    &__time {
      display: inline-block;
    }
  }

  .twoSideWrapper {
    margin-top: 20px;
  }

  .sectionDesc,
  .twoSideWrapper {
    @include mixin.font(18px, 26px);
    color: $secondaryBusColor;
    margin-top: 8px;

    @include rwd.breakpoint(phonesDown) {
      @include mixin.font(16px, 24px);
    }

    span,
    .wrapper {
      @include mixin.font(14px, 20px);
      display: block;
    }

    .wrapper {
      margin: 0;
    }
  }
}

.signs {
  background-color: $whiteColor;
  border-radius: $border-radius;
  border: 2px solid $mainBusColor;
  display: inline-block;
  margin-right: 14px;
  margin-top: 10px;
  padding: 4px 12px;
  text-decoration: none;
  transition: $transition;
  margin-bottom: 5px;

  &__busStop {
    @include mixin.font(18px, false, 700);
  }

  &__post {
    font-size: 14px;
    margin-left: 16px;
    transition: $transition;
    color: $c-boulder-appr;
    white-space: nowrap;
    float: right;
    margin-top: 2px;
  }

  &:hover, &:focus {
    @include rwd.breakpoint(smallUp) {
      background-color: $mainBusColor;
      color: $whiteColor;

      .on-demand-badge,
      .signs__post {
        color: $whiteColor;
      }
    }
  }
}

header {
  .busNumberWrapper {
    &.line-suspended,
    &.line-changed {

      &:before {
        border-width: 0 0 24px 24px;
      }
    }
  }
}

// Pokaż przebieg trasy
.routeTooltip {
  @include mixin.font(14px, 18px, 600);
  align-items: center;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;

  input[type="checkbox"] {
    @include mixin.font(14px, 1em);
    accent-color: $c-black;
    background: $whiteColor;
    border-radius: 3px;
    border: 1px solid $c-black;
    box-shadow: none;
    cursor: pointer;
    height: 18px !important;
    margin: 5px;
    position: relative;
    text-align: center;
    transition: all 0.4s ease;
    width: 18px !important;
  }

  label {
    cursor: pointer;
  }
}

// Zmiany w komunikacji box
.announcedWrapper {
  background-color: $busBorderColor;
  margin-top: 20px;
  box-shadow: $boxShadow;

  .announcedHeader {
    padding: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include rwd.breakpoint(mobile) {
      padding: 10px;
    }

    .readMoreWrapper  {
      width: 82px;
    }

    .readMore {
      &:after {
        transform: rotate(90deg);
      }

      &:hover,
      &:focus {
        @include rwd.breakpoint(smallUp){
          &::after {
            right: 5px;
          }
        }
      }
    }
  }

  .announcedTitle {
    @include rwd.breakpoint(mobileUp) {
      display: flex;
      align-items: center;
    }

    span {
      margin-left: 5px;

      @include rwd.breakpoint(mobile) {
        display: none;
      }
    }
  }

  .title {
    @include mixin.font(20px, 24px, 800);
    margin: 0;

    @include rwd.breakpoint(mobile) {
      @include mixin.font(16px, 20px);
    }

    &.importantNote {
      padding-left: 30px;

      &::before {
        @include mixin.font(15px, false, 600, $c-white);
        @include mixin.flexCenter();
        @include mixin.makeCircle(20px, $c-amaranth);
        @include mixin.position(absolute, 2px, false, false, 0);
        content: "!";
        z-index: 1;

        @include rwd.breakpoint(mobile) {
          top: 0;
        }
      }
    }

    button {
      @include mixin.font(inherit, false, inherit);
      border: none;
      background: transparent;
    }
  }

  .announcedBox {
    padding-top: 8px !important;

    .announcedItem {
      @include mixin.font(14px, 20px);
      background-color: $whiteColor;
      box-shadow: $boxShadow;
      margin-bottom: 4px;
      transition: $transition;

      .announcedWrapDesc {
        text-decoration: none;
      }

      .announcedDesc {
        @include mixin.showLines(4);
        margin-top: 4px;
      }

      .announcedSubItem {
        text-decoration: none;
        max-width: 260px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 10px;
        height: 100%;

        @include rwd.breakpoint(phonesDown) {
          max-width: 230px;
        }

        > div {
          position: relative;
        }
      }
    }
  }

  .announcedSlider {
    display: none;
    
    &.shortList {
      .announcedItem:not(:last-of-type) {
        margin-right: 20px;
      }
  
      .my-slider-progress {
        display: none;
      }
    }
  }

  &.isExpanded {
    .readMore {
      &:after {
        transform: rotate(270deg);
      }
    }
  }

  &.newChanges {
    background-color: $c-lavender-blush;

    .splide__arrow--next {
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        $c-lavender-blush 100%
      );
    }
    .splide__arrow--prev {
      background: linear-gradient(
        -90deg,
        rgba(255, 255, 255, 0) 0%,
        $c-lavender-blush 100%
      );
    }
  }

  .notification {
    display: inline-block;
    padding: 5px;
    letter-spacing: 1px;

    &[data-other-lines] {
      background-color: transparent;
      height: 100%;

      &::after {
        @include mixin.font(16px, 24px, 500);
        @include mixin.position(absolute, 22px, 0);
        background-color: #222;
        border-radius: $border-radius;
        box-shadow: $boxShadow;
        color: $whiteColor;
        content: attr(data-other-lines);
        max-width: 220px;
        width: max-content;
        padding: 4px 12px;
        visibility: hidden;
        z-index: 1;
        letter-spacing: 0.8px;
      }

      &:focus,
      &:focus-within,
      &:hover {
        &::after {
          visibility: visible;

          @include rwd.breakpoint(tabletmobile) {
            display: none;
          }
        }
      }
      
      &.without-after-element {
        &::after {
          display: none;
        }
      }
    }
  }

  .notification-valid-from-lines-list{
    border-radius: $border-radius;
    border: 2px solid $c-botticelli;
    display: inline-block;
    width: 40px;
    padding: 1px 4px;
    text-align: center;
    font-weight: 700;
  }

  .notification-valid-from,
  .notification-valid-from-line {
    font-weight: 600;
  }

  .notification-valid-from {
    display: inline-block;
    margin-top: 8px;
  }
}


//ważny komunikat - zmiany w komunikacji
.importantNote {
  position: relative;
  z-index: 1;

  .stickyNote {
    @include mixin.flexCenter();
    @include mixin.font(15px, false, 600, $c-white);
    @include mixin.makeCircle(20px, $c-amaranth);
    @include mixin.position(absolute, -5px, 5px);
    z-index: 2;
  }
}

// Oznaczenie - przystanek na życzenie
.on-demand-badge,
.on-demand-badge-list {
  color: $c-gray;
  cursor: default;
  font-weight: 400;
} 

.on-demand-badge-list  {
  font-size: 12px;
}

.on-demand-badge {
  font-size: 14px;
  margin-left: 10px;
}

//   Overlay oraz loader przy przeładowaniu kontentu
[data-timetable-content].loading {
  position: relative;

  &:before,
  &:after {
    content: "";
    display: block;
  }

  &:before {
    @include mixin.position(absolute, 20vh, false, false, 50%);
    background-image: url("../../themes/img/loading-blue.svg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 100px;
    transform: translate(-50%, -50%);
    width: 100px;
    z-index: 1002;

    @include rwd.breakpoint(mobile) {
      top: 25vh;
    }
  }

  &:after {
    @include mixin.absoluteCover();
    background-color: $whiteColor;
    opacity: 0.7;
    z-index: 1001;
  }
}

[data-timetable-content].articleNew + .t-template.c-related {
  margin-bottom: 100px;


  @include rwd.breakpoint(mobile) {
    margin-bottom: 80px;
  }
}

//   Legenda oznaczeń kolorystycznych
.legendList {
  display: flex;
  margin-top: 30px;

  .legendItem {
    padding-left: 20px;
    margin-right: 35px;
    position: relative;

    &:last-of-type {
      margin-right: 0;
    }

    &:before {
      @include mixin.position(absolute, 1px, false, false, 0);
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-width: 0 0 14px 14px;
      border-style: solid;
    }

    &.suspended {
      &:before {
        border-color: transparent transparent $suspendedLinesColor transparent;
      }
    }

    &.canceled {
      &:before {
        border-color: transparent transparent $canceledLinesColor transparent;
      }
    }
  }
}

// Legenda textowa
.timetableLegendList {
    @include rwd.breakpoint(phonesUp) {
      &:before {
        display: none;
      }
    }
  
    .timetableLegendItem {
      display: flex;
  
      &.hidePhone {
        @include rwd.breakpoint(phonesDown) {
          display: none;
        }
      }
    }
  
    .legendDesc {
      flex: 1;
      position: relative;
      padding-bottom: 10px;
  
      @include rwd.breakpoint(phonesDown) {
        margin: 10px 0;
      }
  
      &:after {
        @include mixin.position(absolute, false, false, 0, 0);
        display: block;
        content: "";
        height: 1px;
        width: 30px;
        background-color: $accentBusColor;
      }
    }
  }

.timetableLegendWrapper {
    @include rwd.breakpoint(phonesDown) {
      padding-bottom: 15px;
      position: relative;
  
      &.expanded {
        .timetableLegendList {
          padding-bottom: 20px;
  
          &:before {
            display: none;
          }
        }
  
        .displayMoreInfo  {
          .readMore::after {
            transform: rotate(270deg);
          }
        }
      }
    }
  
    .slideTxt ul {
      margin: 0;
    }
  
    .displayMoreInfo {
      @include rwd.breakpoint(phonesUp) {
        display: none;
      }
  
      @include rwd.breakpoint(phonesDown) {
        @include mixin.position(absolute, false, false, 0, 50%);
        z-index: 15;
        transform: translate(-50%, -50%);
  
        .readMore {
          width: 140px;
  
          &:after {
            transform: rotate(90deg);
          }
        }
      }

      &.hidePhone {
        display: none;
      }
    }
  }

// Btn - drukuj stronę
.printPageWrapper {
  margin-top: 80px;
  margin-bottom: 40px;
  text-align: right;

  .printPage {
    background-color: transparent;
    border: 1px solid var(--main-color);
    color: initial;
    cursor: pointer;
    display: inline-block;
    font-weight: 600;
    padding: 5px 10px 5px 40px;
    position: relative;
    text-decoration: none;
    transition: $transition;

    &:before {
      @include mixin.position(absolute, calc(50% - 9px), false, false, 15px);
      background-image: url("../img/printer.png");
      background-repeat: no-repeat;
      background-size: contain;
      content: "";
      display: block;
      height: 18px;
      transition: $transition;
      width: 16px;
    }

    &:hover,
    &:focus {
      @include rwd.breakpoint(smallUp) {
        color: $mainBusColor;
        text-decoration: underline;

        &:before {
          left: 11px;
        }
      }
    }
  }
}

a.lnkRouteMap {
  display: block;
  margin-bottom: -20px;
  margin-top: 20px;
  overflow: hidden;
  position: relative;

  &::after {
    @include mixin.absoluteCover();
    background-color: rgba(0, 0, 0, 0.5);
    content: "";
    filter: alpha(opacity=0);
    opacity: 0;
    transition: $transition;
    z-index: 5;
  }

  span {
    @include mixin.font(20px, 24px, 600, $c-white);
    @include mixin.centerer(false, true);
    left: -100%;
    position: absolute;
    text-align: center;
    transition: all 0.5s ease;
    width: 100%;
    z-index: 6;
  }

  &:hover {
    &::after {
      opacity: 1;
    }

    span {
      left: 0;
    }
  }
}
