@use "../../../../../../assets/sass/abstract/_mixins" as mixin;

// Wydruk strony w poziomie
@media print {
  @page {
    size: landscape;
  }
}

@media print {
  $printBorder: 1px solid #808081;

  html,
  .busPrint {
    height: 100%;
    overflow: hidden;
  }

  [data-main="komunikacja"] {
    .cache-manage-main-button,
    .phpdebugbar,
    .display-curr-git-branch-name,
    .hotNewsBar {
      display: none;
    }
  }

  .busPrint {
    padding: 10px 10px 0;

    // Ukryj element
    .bigAdBox,
    .hideOnPrint,
    .c-breadcrumb,
    .cky-consent-bar.cky-banner,
    .closest-trips,
    .displayMoreInfo > .icon,
    .linearShadow:before,
    .listBreadcrumbs,
    .mainDir,
    .mainFooter,
    .mainHeader,
    .mainLogoBar,
    .navbar.navbar-default,
    .pageInfoBar,
    .printPageWrapper,
    .smallBtn,
    .tabList,
    .topLangSearch,
    .topLogo,
    .twoSides__leftSide .contentWrapper,
    .updatesSlider,
    .aside.mainAside,
    footer#footerContact,
    header > .container,
    ins.adCreator,
    .announcedWrapper,
    .busStopsList,
    .routeTooltip,
    .readMoreWrapper,
    .timetable-versions-alert .txtCategoryLabel,
    .busContent .busTimetableHeader .iconWithBg,
    .busContent .busTimetableBox .updateSelect:after,
    .timetableTableWrapper #content-niedziela .timetableLegendWrapper,
    .timetableTableWrapper #content-sobota .timetableLegendWrapper,
    .timetableTableWrapper #content-w-dni-wolne .timetableLegendWrapper,
    .timetableTableWrapper #content-17-18-iv .timetableLegendWrapper,
    .timetableTableWrapper #content-pt-sob .timetableLegendWrapper,
    .timetableTableWrapper #content-sob-niedz .timetableLegendWrapper,
    .busStopTwoAccordionContainer
      .accordionContent
      .listLinkWrapper
      .listLinkItem:not(:first-of-type),
    .busStopTwoAccordionContainer .busVariantShape .leaflet-top.leaflet-left,
    .busContent .infoBannerList,
    .busContent .sectionLink,
    .lnkRouteMap,
    .grv-dialog-host,
    .bannerBox,
    ._hj_feedback_container,
    .grv-helper-bell-host,
    .kodabots-widget-animated-4gHwe12,
    .bottomStickyBar,
    .busDirectionWrapper.arrowDown::after,
    .box-container.t-template.c-related {
      display: none;
    }

    // Wyświetl kolor tła
    .simplebar-track.simplebar-vertical,
    .simplebar-scrollbar:before,
    .accordionContent.onMobileClose .accordionBody .listItem::after,
    .smallNumberWrapper,
    .bus-table,
    .bus-table li[time-hour]:nth-child(even),
    .timetableLegendList .legendDesc::after,
    .signs__busStop,
    .signs__post,
    .icon-bus-clock,
    .twoSides .listItem.active .listLinkWrapper,
    .legendList .legendItem.suspended:before,
    .legendList .legendItem.canceled:before,
    .lightBgWrapper {
      -webkit-print-color-adjust: exact;
    }

    // Zapobiega wstawianiu podziału (strony, kolumny lub regionu) w polu głównym
    .busTimetableContainer,
    .timetableLegendList,
    .timetableLegendWrapper {
      break-inside: avoid;
    }

    .bus-table .tbody,
    .bus-table .bus-table-title,
    .timetableLegendList {
      page-break-before: auto;
      page-break-after: auto;
    }

    .timetableTableWrapper,
    .timetableTableWrapper .timetableTable {
      break-inside: auto;
    }

    // Kolor tekstu
    li[time-hour-minutes].highlighted__time0,
    li[time-hour-minutes].highlighted__time1,
    li[time-hour-minutes].highlighted__time2,
    .bus-table .out-dated,
    .on-demand-badge-list,
    .on-demand-badge,
    .busTimetableBox .titleSection,
    .busTimetableBox .currentTimetable {
      color: $body-color;
    }

    li[time-hour-minutes].highlighted__time0,
    li[time-hour-minutes].highlighted__time1,
    li[time-hour-minutes].highlighted__time2 {
      background-color: initial;
      height: auto;
      width: auto;
      margin: 0;
      border-radius: none;

      .smallLegendMark {
        top: -2px;
        right: -1px;
      }
    }

    .container {
      max-width: 100%;
    }

    // bus_stops_list
    .busStopListing {
      .accordionContent.onMobileClose .accordionBody {
        display: block;

        .accordionList {
          columns: 2;
        }
      }
    }

    // bus_list | bus_stops
    .busStopTwoAccordionContainer {
      display: block;

      .accordionContent {
        &.col-lg-6 {
          flex: 0 0 100%;
          max-width: 100%;
          width: 100%;
        }

        &.onMobileClose .accordionBody,
        &.isCurled .accordionBody {
          display: block;
        }
      }
    }

    // bus_list
    .busContent {
      .busTimetableHeader {
        margin-bottom: 10px;

        .mainTitle {
          @include mixin.font(16px, 18px, 500);
        }

        .sectionDesc {
          margin-top: 8px;
        }
      }

      .busTimetableBox {
        .currentTimetable {
          box-shadow: none;
          padding-right: 0;
        }
      }
    }

    //PRINT STYLES - bus_station
    .timetable-versions-alert {
      box-shadow: none;
      margin-top: 0;
      margin-bottom: 5px;
    }

    .printWrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .busDirectionWrapper {
      padding-top: 0;
      margin-top: 0;

      .onlyPrint {
        @include mixin.font(9px, false, 600);
        margin-bottom: 2px;
        text-transform: uppercase;
        margin-right: 20px;
      }
    }

    .onlyPrint {
      display: inline-block;
    }

    .headerContentImg {
      display: block;
    }

    .signs {
      padding: 2px 5px;
      margin-top: 5px;
    }

    .signs__busStop,
    .signs__post {
      @include mixin.font(12px, false, 500);
    }

    .signs__post {
      margin-top: 0;
      float: none;
    }

    .infoBox__time {
      font-size: 10px;
    }

    .on-demand-badge,
    .on-demand-badge-list {
      font-size: 8px;
    }

    .line__changed {
      font-size: 12px;
    }

    .twoSides {
      margin-top: 0;

      &__leftSide {
        margin-right: 10px;
      }

      .accordionHeader,
      .accordionBody {
        box-shadow: none;
      }

      .accordionContent {
        width: 190px;
      }

      .accordionBody {
        padding-top: 5px;
      }

      .accordionHeader {
        min-height: auto;
        border-bottom: 3px solid #808081;
      }

      .smallNumberWrapper {
        display: none;
      }

      .busDirection {
        span {
          @include mixin.font(12px, 16px, 500);
        }
      }

      #accordionWithScroll {
        height: 100%;

        ul {
          list-style-type: disc;
        }

        .simplebar-track.simplebar-vertical {
          background-color: transparent;
        }

        .simplebar-scrollbar:before {
          background-color: transparent;
        }
      }

      .simplebar-content-wrapper {
        overflow: unset;
      }

      .accordionList {
        padding: 0;
        margin-left: 0;
        border-left: none;
      }

      .listLinkWrapper {
        @include mixin.font(10px, 13px, 400);
        padding: 0 0 0 5px;
        border: none;
        border-radius: 0;
        min-height: auto;
      }

      .listItem {
        margin-bottom: 0;

        &.active {
          .listLinkWrapper {
            background-color: #e8e8e8;
          }

          a {
            color: $body-color;
            font-weight: 600;
          }
        }

        .icon,
        .topLabel {
          display: none;
        }
      }
    }

    .smallLegendMark {
      font-size: 8px;
      top: -2px;
      right: -1px;
    }

    .titleSection {
      @include mixin.font(12px, false, 500);
    }

    .leftBeam {
      margin-bottom: 0;
      margin-top: 15px;
      border: none;
      padding: 0;
    }

    .legendDesc {
      @include mixin.font(9px, 12px);
      padding: 0;
      margin: 0;

      &::after {
        display: none;
      }
    }

    .timetableLegendWrapper {
      height: 100%;
    }

    .articleNew header,
    main.col-md-9 > header {
      margin: 0;
    }

    h3.leftBeam {
      border-left: 3px solid $accentBusColor;
      margin-top: 10px;
      margin-bottom: 15px;
      padding: 4px 10px;
    }

    .tbody {
      border: $printBorder;
    }

    .bus-table-title {
      @include mixin.font(12px, false, 600);
      clip: unset;
      display: block;
      border: $printBorder;
      text-transform: uppercase;
      height: auto;
      overflow: visible;
      position: unset;
      width: 100%;
      text-align: center;
      padding: 5px 0;
    }

    .bus-table {
      font-size: 10px;

      .td,
      li[time-hour-minutes],
      .th {
        width: 24px;
        height: 24px;
      }

      .td li[time-hour-minutes] {
        font-weight: 500;
      }

      .th {
        font-weight: 600;
      }

      li[time-hour] {
        &:nth-child(even) {
          background-color: #d5dde8;

          .th.colorText {
            background-color: #b6b9bd;
          }
        }
      }
    }

    .th.colorText {
      border-right: $printBorder;
    }

    .busNumberWrapper {
      border: $printBorder;
      height: 90px;
      width: 90px;
      margin-right: 15px;

      .busNumber {
        @include mixin.font(30px, false, 600);
      }
    }

    .timetableTableWrapper {
      @include mixin.flexValue();
      border-top: none;
      break-inside: 0;
      position: relative;
      padding-bottom: 100px;
      height: 100%;

      .timetableLegendWrapper {
        @include mixin.position(absolute, false, 0, false, 0);
      }

      .timetableTable {
        display: block !important;
        margin-bottom: 30px;
        margin-top: 0;
        flex: 1;
      }
    }

    .timetableLegendList {
      .legendDesc {
        padding-bottom: 6px;

        &:after {
          background-color: $accentBusColor;
        }
      }
    }

    &.forcedColors .bus-table .out-dated {
      text-decoration: unset;
    }
  }
}
