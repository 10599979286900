@use "../../../../assets/sass/abstract/_responsive.scss" as rwd;

.servicesPromoBox {
  .sectionTitle,
  .sectionTitle a {
    color: #232323;
  }

  .projectsSlider .boxTitle {
    color: #1c3156;
  }
}

//sutryk NW servicesPromoBox - nadpisane style strzałek i btn
// bo defaultowo wspolny kolor z --services-promo-box-details-color
.servicesPromoBox .splide.arrowsLight .splide__arrow:before {
  color: #73b0e2;
}

.servicesPromoBox .rightSide__content .lnkBtn {
  background-color: #529fdf;
  border-color: #529fdf;
}

//promoBoxSliderSection dark
// uwaga arrows slidera nie mają kolorów z const - nie zaciagają z --promo-box-slider-sign-color:
.promoBoxSliderSection {
  .promoSlider {
    .splide__arrow:before {
      color: #b6edff;
    }
  }
}

.s-shortsYtSliderPopUp {
  .sectionTitle:before {
    border-top-color: #b6edff;
  }

  .splide__pagination__page.is-active {
    border-top-color: var(--promo-box-slider-sign-color);
  }
}

.promoBoxSliderSection.defaultView {
  @include rwd.breakpoint(mobile) {
    min-height: 400px !important;
  }
}

.iconSlider .sectionTitle {
  @include rwd.breakpoint(phonesDown) {
    margin-bottom: 6px !important;
  }
}